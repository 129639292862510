// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("packs/custom")

import 'bootstrap'
import '../../assets/stylesheets/application'
import sftpModal from '../pages/sftp_modal'

import $ from "jquery"
document.addEventListener("turbolinks:load", () => {
  console.log("Turbolinks");
  // find a better way to add the listener
  if (document.querySelector('#days-estimator')) {
    document.querySelector('#days-estimator').addEventListener('click', function(event) {
        console.log('Estimate Days (Running PR)');
        var scenario = document.getElementById("scenario").value;
        var optimization_factor = document.getElementById("optimization_factor").value;
        if (optimization_factor === "select")
          optimization_factor = "duration_cost"
        console.log(optimization_factor + " " + scenario);
        $.ajax({
          url: "/scheduler/estimate",
          data: {"renovation_scenario_id": scenario, "optimization_factor": optimization_factor},
          dataType: 'json',
          success: function(response, status, obj){
            document.querySelector('#estimated-days').innerHTML = response['duration'];
            $("#days-estimator").blur();
          }
        });
    });
  }

  if (document.querySelector(".add-wp-btn")) {
    console.log('renovation edit-update page');
    var selectArea = document.querySelector("#renovation_scenario_work_package_ids");
    selectArea.disabled = true;
    var buttons = document.querySelectorAll(".add-wp-btn");
    console.log(buttons);
    buttons.forEach(function (btn) {
      if (selectArea.length !== 0 && selectArea.querySelector('option[value="' + btn.getAttribute("data-value") + '"]'))
        btn.disabled = true;
      btn.addEventListener('click', function (event) {
        console.log("button clicked");
        $(btn).blur();
        console.log(btn.getAttribute("data-value"));
        var option = document.createElement("option");
        var parent = btn.parentElement;
        option.text = [].reduce.call(parent.childNodes, function(a, b) { return a + (b.nodeType === 3 ? b.textContent : ''); }, '');;
        option.value = btn.getAttribute("data-value");
        option.selected = true;
        selectArea.add(option);
        btn.disabled = true;
        document.querySelector('.rmv-wp-btn[data-value="' + btn.getAttribute("data-value") + '"]').disabled = false;
      });
    });

    var buttons = document.querySelectorAll(".rmv-wp-btn");
    console.log(buttons);
    buttons.forEach(function (btn) {
      if (selectArea.length !== 0)
        btn.disabled = false;
      btn.addEventListener('click', function (event) {
        console.log("button clicked");

        var option = selectArea.querySelector('option[value="' + btn.getAttribute("data-value") + '"]');
        console.log("Index deleted: " + option.index);
        selectArea.remove(option.index);
        $(btn).blur();
        btn.disabled = true;
        console.log(document.querySelector('.add-wp-btn[data-value="' + btn.getAttribute("data-value") + '"]'));
        document.querySelector('.add-wp-btn[data-value="' + btn.getAttribute("data-value") + '"]').disabled = false;
      });
    });

    var submitBtn = document.querySelector("#scenario-submit-btn");
    submitBtn.addEventListener('click', function (event) {
      selectArea.disabled = false;
    });
  }

  if (document.querySelector('.job-row-results')) {
    var items = document.querySelectorAll('.job-row-results svg');
    items.forEach(function (item) {
      var parent = item.parentElement;
      $(item).popover({
        title: "Details",
        html: true,
        content:  "<strong>Duration: </strong> " + parent.dataset.duration + " days<br>" +
                  // "<strong>Partner: </strong> " + parent.dataset.partner + "<br>" +
                  "<strong>Description: </strong> " + parent.dataset.description + "<br>" +
                  "<strong>Internal Env. Disruption: </strong> " + parent.dataset.d_environment + "/4.0<br>" +
                  "<strong>Physical Space Disruption: </strong> " + parent.dataset.d_physical_space + "/4.0<br>" +
                  "<strong>Utilities Disruption: </strong> " + parent.dataset.d_utilities + "/4.0<br>" +
                  // "<strong>Cost/hour:</strong> " + parent.dataset.cost_hour + "€<br>" +
                  // "<strong>Cost/day:</strong> " + parent.dataset.cost_day + "€<br>" +
                  "<strong>Workers:</strong> " + (Math.round(parent.dataset.workers * 100) / 100) + "<br>",
        trigger: 'click',
        boundary: 'window'
      })
    });
  }

  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();

  sftpModal()
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
