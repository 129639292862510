export default function() {
  const modal = $('#sftpModal')
  const form = $(modal).find('form')[0]
  const spinner = $(modal).find('.spinner-border')[0]
  const submitBtn = $(modal).find('input[type=submit]')[0]

  $(submitBtn).on('click', function() {
    $(this).attr('disabled', 'disabled')
    $(this).blur()
    $(spinner).show()
    $(form).submit()
  })
}
